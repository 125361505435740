import React from 'react';
import './InternationalAddress.css'; // This is where we will write our CSS
import NewZealand from '../../Images/newzelandflag.jpg'
import Malaysia from '../../Images/malasiya.jpg'
import Srilanka from "../../Images/SrilankaFlag.jpg"
import India from "../../Images/IndianFlag.png"



const InternationalAddresses = () => {
  return (
    <div className="international-addresses mb-12">
      <div className="address-card">
        <img src={India} alt="India" />
        <h2>India</h2>
        <p>12-7-3/1,Lane beside St. Anthony’sChurch</p><p>Mettuguda,Secunderabad
          -500017,Telangana, India.</p>
        <p>Ph :+91-40-49500017</p>
        <p>+91- 9100954463/73</p>
        <p>Mail :ea.join@gmail.com</p>
        <p>Skype: edexnz</p>
      </div>

      <div className="address-card">
        <img src={NewZealand} alt="New Zealand" />
        <h2>NEW ZEALAND</h2>
        <p>P O Box 15-654, New Lynn, Auckland 0600 New Zealand.</p>
        <p>Ph: +1(800)123-4567</p>
        <p>Mail: elisha@edexacademy.org</p>
        <p>Skype: edexnz</p>
      </div>
      <div className="address-card">
        <img src={Malaysia} alt="Malaysia" />
        <h2>MALAYSIA</h2>
        <p>B-1-B, Block B, Level 1, Sunway PJ@51A, Jalan SS9A/19, SeriSetia, 47300 Petaling Jaya, Selangor, Malaysia.</p>
        <p>Ph: +64 21 032 2115</p>
        <p>Mail: ea.join@gmail.com</p>
        <p>Skype: edexnz</p>
      </div>
      <div className="address-card">
        <img src={Srilanka} alt="Sri Lanka" />
        <h2>SRI LANKA</h2>
        <p>#35, 4th Lane Angulana Station road, Moratuwa Sri Lanka.</p>
        <p>Ph: +94 77 770 9049</p>
        <p>Mail: ea.join@gmail.com</p>
        <p>Skype: edexnz</p>
      </div>
      
    </div>
  );
};

export default InternationalAddresses;
