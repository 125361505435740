import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqMalaysia() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>Benefits of studying in Malaysia</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Why study in malaysia ?</Accordion.Header>
                    <Accordion.Body >
                        <p>Malaysian colleges and universities offer a wide variety of courses. Undergraduate and postgraduate programmes are offered in various fields, including pharmacy, medicine, engineering, law, business, economics, commerce, information technology and education. Outside the classroom, there are lots of activities you can explore. You can either visit the islands of Penang, Langkawi, Pangkor and Tioman and experience a nice beach holiday, or have a look at the colourful stages of Malay history in Penang. You can also head down to the famous Batu caves, a 400-million-year-old limestone hill with a series of awe-inspiring caves and cave temples.</p>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Study Options </Accordion.Header>
                    <Accordion.Body>
                    Academic intakes vary from institution to institution. Most Malaysian universities have academic intakes in late February and in mid-July. Some universities (branch campuses) might have intakes in October or second or third round offer intakes. Some popular engineering courses are available at Malaysian universities, which mostly commence from February or June.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Cost of Stuydying in Malaysia</Accordion.Header>
                    <Accordion.Body>
                    The tuition fees will vary based on the institution (public or private) and the programme that you apply for. The tuition fees at a branch campus will surely vary from a parent campus. Undergraduate programmes in Business and Engineering at a branch campus could cost from RM 48,000 to RM 102,000 per year. One of the most popular reasons why international students choose Malaysia is its relatively affordable cost of living and tuition fees. Students will also find that the accommodation options are quite affordable.

Accommodation could cost from RM600 to RM900 per month, three meals per day could cost from RM 20 to RM 30, transport could cost from RM 80 to RM 150, campus mobile phone bills could cost from RM 30 to RM 50 and personal expenses could cost from RM 100 to RM 200.                    </Accordion.Body>
                </Accordion.Item>

               

                <Accordion.Item eventKey='4' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>scholarship for international students </Accordion.Header>
                    <Accordion.Body>
                    There are various scholarships on offer in various fields, such as Science and Engineering, Agriculture and Fisheries, Biotechnology, Information and Communication Technology, Food Security and Environmental Studies.

Some of the popular scholarships that are on offer are as follows:

Malaysian Technical Cooperation Programme (MTCP) Scholarship
Malaysian International Scholarship (MIS)
Malaysian Commonwealth Scholarship</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='5' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Benifits of studying in malaysia </Accordion.Header>
                    <Accordion.Body>
                    Multicultural environment where students get to know various people from different religion and culture.

A politically stable country.

International standard of education, with post-study work opportunities. Since Malaysia looks for skilled workers, the industries are in need for good graduates always.

A range of programmes are on offer for students, along with twinning programmes and programmes that are on offer by branch campuses.

Straightforward immigration procedures that make it easier for students to transit to the country.

Safe country for international students. As per the Global Peace Index, Malaysia ranks 29th on the list of most peaceful country in the world, out of a list of 163 countries.

Fantastic travel destination for travelholics!

Malaysia is a food haven for those who can’t live without experiencing some incredible cuisines in the world.

Students can receive degrees from countries like the UK, the USA and Australia.

Last but not least, Malaysia is an affordable study destination for students.
                    </Accordion.Body>
                </Accordion.Item>

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqMalaysia;