import React from 'react';
import './PatnerAddress.css'; // This is where we will write our CSS
import MumbaiImage from   "../../Images/mumbai.jpg";
import ChennaiImage from "../../Images/chennai.jpg"
import CoambatorieImgge from "../../Images/coimbatore.jpg"

const PartnerAddresses = () => {
  return (
    <div className="partner-addresses">
      <h1>Partner's Addresses</h1>
      <div className="addresses">
        <div className="address">
          <img src={CoambatorieImgge} alt="Coimbatore" />
          <h2>COIMBATORE</h2>
          <p>JAI VAARI ENCLAVE No.6, 6th street, K.K.Pudur, Saibaba Colony, Coimbatore-641038, INDIA.</p>
        </div>
        <div className="address">
          <img src={MumbaiImage} alt="Mumbai" />
          <h2>MUMBAI</h2>
          <p>#738, 7th Floor, Commodity Exchange, Plot No. 2-3-4, Sector No. 19, Near APMC, Market, Vashi, Navi Mumbai-400705, Maharashtra, India.</p>
        </div>
        <div className="address">
          <img src={ChennaiImage} alt="Chennai" />
          <h2>CHENNAI</h2>
          <p>4/10, 5th Main Road, 2nd Floor, Bharath Crown Apartments, Dhandeeswarnagar, Velachery, Chennai-600 042, Tamil Nadu, India.</p>
        </div>
      </div>
    </div>
  );
};

export default PartnerAddresses;
