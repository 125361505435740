import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqVisa() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5' style={{color:'darkgreen'}}><i>We assist students to fill the visa forms as per the country's procedure</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Assitance is filling visa forms:</Accordion.Header>
                    <Accordion.Body >
                    We assist the students to fill in the visa forms as per the country’s procedure. Some of them have online and others have paper based forms.                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>procedure for arrangement of  Documents </Accordion.Header>
                    <Accordion.Body>
                    Every country has a separate procedure for visa, some have online submissions, some have in-person submissions and others have a personal interview. We at EDEX guide the students through every step to ensure a hassle-free process.

</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Mock Interview Sessions</Accordion.Header>
                    <Accordion.Body>
                    As part of visa procedures many embassies conduct via interviews via Phone, Skype or in person. We at EDEX decipher the
interview process through carefully programmed sessions in which we provide the students with requisite tools and information required. We also conduct
mock sessions to enable students to practice their replies and get over nervousness and anxiety.
                    </Accordion.Body>
                </Accordion.Item>
               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqVisa;