import React from 'react';
import './Courses.css';
import { Card } from 'react-bootstrap';
import AUTSERVICEIMAGE from '../../Images/Aut.jpg';
import EDUCATIONLEVELIMAGE from '../../Images/education-level.webp';
import CareerCousellingIMAGE from '../../Images/careercouselling.png';
import ScholarshipImage from '../../Images/scholarship.webp';
import EducationCourseImg from '../../utils/images/education-course.jpg';
import UniversitySelectionImage from '../../Images/UniversitySelection.jpg';
import Admissions from '../../Images/admissions.png';
import ApplicationProcessImage from '../../Images/AdmissionProcess.webp';
import EducationLoanImage from '../../Images/educationloan.jpg';
import VisaGuidanceMockInterview from "../../Images/visa.webp"
import TravelAssitanceImg from "../../Images/travelAssistance.jpg"
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { useNavigate } from 'react-router-dom';

const courses = [
    {
        id: 1,
        img: [AUTSERVICEIMAGE],
        title: 'AUT',
        description: 'Why study at AUT as an international student ?',
        navroute:"/aut"
    },
    {
        id: 2,
        img: [EDUCATIONLEVELIMAGE],
        title: 'Education Level',
        description: 'Provides Training and Occupational Skills course work for greater employment and advancement possibilities.',
        navroute:"/educationlevel"

    },
    {
        id: 3,
        img: [CareerCousellingIMAGE],
        title: 'Career Couselling',
        description: 'Most of the students are in a dilemma about various career options to choose and We can guide them',
        navroute:"/careercounselling"
    },
    {
        id: 4,
        img: [ScholarshipImage],
        title: 'Scholarships',
        description: 'Know more about Scholarships',
        navroute:"/scholarship"
    },
    {
        id: 5,
        img: [UniversitySelectionImage],
        title: 'University Selection',
        description: 'Edex will help you and guide you to reach the universities you like',
        navroute:"/universityselection"
    },
    {
        id: 6,
        img: [Admissions],
        title: 'Admissions',
        description: 'Click to know more about Admissions',
        navroute:"/admissions"
    },
    {
        id: 7,
        img: [ApplicationProcessImage],
        title: 'Application Process',
        description: 'Click to know how we help to process your application',
        navroute:"/applicationprocess"
    },
    {
        id: 8,
        img: [EducationLoanImage],
        title: 'Education Loan',
        description: 'Click to get more details',
        navroute:"/educationloan"
    },
    {
        id: 9,
        img: [VisaGuidanceMockInterview],
        title: 'Visa Guidance / Mock Interview',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!',
        navroute:"/visa-guidance-mock-interview"
    },
    {
        id: 10,
        img: [TravelAssitanceImg],
        title: 'Travel Assitance',
        description: 'Know about travel assitance from Edex',
        navroute:"/travelassistance"
    },
];






function Courses() {


    const navigate = useNavigate();

   
  



  return (
    <div className='courses-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Our Services</h1>
                <p className='text-center w-75 mb-5'>Down below are the services we offer</p>
            </div>
        </header>

        <div className='container py-5'>
            <div className='row g-4'>
                {courses.map((course) => (
                    <div key={course.id} className='col-lg-6' onClick={()=>{ navigate(courses[course.id-1]["navroute"])}}>
                        <Card className='text-white shadow scale-hover-effect' >
                            <Card.Img src={course.img} />
                            <Card.ImgOverlay className='d-flex flex-column align-items-center justify-content-center p-md-5'>
                                <Card.Title className='fs-1 ' style={{color:'#9ACD32'}}>{course.title}</Card.Title>
                                <Card.Text className='text-center'>{course.description}</Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </div>
                ))}
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <FaqAccordion />
        </div>
    </div>
  )
}

export default Courses;