import React from 'react';
import './EducationLevel.css'; 
import EducationalLevelImage from "../../../Images/education-level.webp"

const EducationLevel = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Education Level</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={EducationalLevelImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Education Level</h5>
              <p className="card-text">
                <strong>DIPLOMA:</strong> Provides Training and Occupational Skills course work for greater employment and advancement possibilities. Normally 80% course is dedicated towards attainment of practical skills. Apprenticeship is an integral part of these courses. Most of the programs are of 8-12 months and are targeted for individuals between 16-19 years of age. Ex: Automotive Technician, Diesel and Heavy Mechanic Technician, Welding, Pharmacy Technician etc...
              </p>
              <p className="card-text">
                <strong>ADVANCED DIPLOMA:</strong> Are an Extension of Diploma and includes more theory and specialization on the subject of study. Most of the countries treat it as a part of undergraduate degree and have progression pathways. Mostly taken up by professionals as an extension of existing qualifications. Ex: Accounting & Finance, Animation, Aviation, Financial Planning etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">ASSOCIATE DEGREE</h5>
              <p className="card-text">
                Associate of Arts/Science, Community College, Occupational Training. Designed to transfer to a 4-year institution or provide skills for a specific occupation.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">BACHELOR'S DEGREE</h5>
              <p className="card-text">
                Undergraduate degree, typically requiring four years of study. Covers general education courses along with specific major courses.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">POST GRADUATE CERTIFICATE</h5>
              <p className="card-text">
                Advanced skill training in a specific field. Usually shorter in duration than a diploma. Ex: Human Resources, Supply Chain Management, Project Management.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">POST GRADUATE DIPLOMA</h5>
              <p className="card-text">
                Provides advanced knowledge and skills. Typically requires one year of study after an undergraduate degree. Ex: PG Diploma in Computer Science, Design etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">MASTERS</h5>
          <p className="card-text">
          Awarded for Study of 2 years after Bachelor’s Degree. It may be Classroom Base, Research based or normally combination of both. It is generally an extension specialization based on Bachelor’s degree. Some countries such as United Kingdom offer Masters ranging for 12-16 Months duration. Normally it is an essential prelude for eligibility of admission into doctorate program. Some of the Examples of Master’s Degree are Masters in Science/Art/Law/ Business/Engineering/ Theology etc…
          </p>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">PHD</h5>
          <p className="card-text">
          These programs are designed as a combination of Masters along with doctorate degree. The basic qualification required for admission is anBachelor’s Degree in the related discipline. These are offered to exceptional candidates who have huge potential and ability to excel in high performance environment. The purpose of the integrated PhD is to gradually condition one’s thoughts towards research, while providing a view of the trials and tribulations in small doses. So when one joins an Int. PhD program, just in 2-3 months, one is shuttled from a semester-and-exam based system to one where you are learning skills and tools all the time, with evaluations based on presentation, data talks and discussions. These programs are offered across all disciplines i.e. Computer Science/All Engineering/Life Sciences etc… and are of 4-5 years duration. PHD: Doctorate degrees are offered after completion of Masters Degree. You’ll learn more in the first year of Int. PhD than you ever will in the first year of any other degree. The fact that mostly premier institutes offer this program ensures that the student would be exposed to cutting-edge technology, world-renowned speakers and an internationally trained faculty. Since the requirements are tough and the course is purely research Based, admissions criteria are usually very stringent. The normal duration of the PhD Program is 5 years. These programs are offered across all disciplines i.e. Computer Science/All Engineering/Life Sciences etc…. Most of the times students are eligible for fellowships while pursuing their PhD’s.
          </p>
        </div>
      </div>


      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">Note !</h5>
          <p className="card-text">
          We have tried to cover basic points of the most common qualifications. There are bound to be exceptions as it varies across different countries. Please email us at ea.join@gmail.com for any further queries.
          </p>
        </div>
      </div>


    </div>
   </div> 
  );
};

export default EducationLevel;
