import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqNewzealand() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 card-text mb-6' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>Advantages of Studying in NewZealand</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Advantages of Studying in Newzealand</Accordion.Header>
                    <Accordion.Body >
                        <ul>
                            <li>World Class Qualifications with global recognition</li>
                            <li>English Speaking Country</li>
                            <li>A vibrant economy, with good growth in jobs</li>
                            <li>Offers Permanent Residency to qualified and eligible students</li>
                            <li>Spouse can accompany student</li>
                            <li>Post Study work Visa can extend upto 3 years</li>
                            <li>Simple Application and visa process</li>
                            <li>Part time work allowed during studies</li>
                        </ul>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Popular Programs </Accordion.Header>
                    <Accordion.Body>
                     <ul>
                        <li>Animation</li>
                        <li>nformation Technology</li>
                        <li>Business Management</li>
                        <li>MBA</li>
                        <li>Accounts and Finance</li>
                        <li>Public Health Programs</li>
                        <li>Sports and Exercise Science</li>
                        <li>Hospitality</li>
                        <li>Engineering</li>
                        <li>Teaching and Education</li>
                     </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Popular Education Institutes:</Accordion.Header>
                    <Accordion.Body>
                        <ul>
                            <li>Auckland University of Technology</li>
                            <li>Eastern Institute of Technology</li>
                            <li>Manukau Institute of Technology</li>
                            <li>Otago Polytechnic</li>
                            <li>Southern Institute of Technology</li>
                            <li>Toi Ohomai Institute of Technology</li>
                            <li>Western Institute of Technology at Taranaki</li>
                            <li>Auckland Institute of Studies</li>
                            <li>Academic Colleges Group</li>
                            <li>Pacific International Hotel Management School</li>
                            <li>New Zealand School of Education Group</li>
                        </ul>

                       </Accordion.Body>
                </Accordion.Item>


               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqNewzealand;