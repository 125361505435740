import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqAdmissions() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5' style={{color:'darkgreen'}}><i>Mandatory Requirements</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Documents</Accordion.Header>    
                    <Accordion.Body >                   
                        <ul>
                            <p>The students need to submit the following documents as part of the admission process. EDEX provides the detailed checklist and guidelines for the preparation of these:</p>
                            <li>Certified Copies of Academic Documents</li>
                            <li>How to Order Transcripts</li>
                            <li>Statement of Purpose, Resume</li>
                            <li>TRecommendation Letters</li>
                            <li>Financial Certificates</li>
                            <li>TExperience Letters</li>
                            <li>Scores of Pre-requisite Exams</li>
                        </ul>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> APPLICATION FEE:
                    </Accordion.Header>
                    <Accordion.Body>
                    Many of the universities have application fees as part of the application process. It has to be paid online by using Credit/ Debit card.
                </Accordion.Body>
                </Accordion.Item>
                          
            </Accordion>
        </div>
    </div>
  )
}

export default FaqAdmissions;