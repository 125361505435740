import React from 'react';
import './Scholarship.css'; 
import ScholarShipImage from "../../../Images/scholarship.webp";
import TGImage from '../../../Images/tggovt.png'
import FaqVisa from './FaqAccordion/FaqAccordion';

const Scholarship = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Scholarships            </h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={ScholarShipImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Scholarships </h5>
              
              <p className="card-text">
              Scholarships can be a great help in planning the financial aspects for education overseas, especially if you have a restrictive financial plan to invest in higher studies.To ensure an upsurge in the number of international students, the universities and colleges all over the world are providing an increased number of international scholarships. From program and major specific to general study abroad scholarships, there is something for everyone.

              Gaining an in depth knowledge will open new possibilities for you and will ease your process to zero in on the right destination.

              Role of our Career Advisors
              Our career advisors assist you to
              Streamline the process
              Navigate your way to the right university
              Avail the right scholarships as per your eligibility
              They also coordinate with the university and assist you with the formalities involved in availing the granted funding.
             </p>
            </div>
          </div>
        </div>
      </div>



      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={TGImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              
              <p className="card-text">
              Govt. of Telangana Scholarship for Backward Classes Students to Study Abroad for PG Programs With a view to enable the benefit of higher education in Foreign Universities to meritorious Backward Classes Students and thereby providing them an opportunity for better career prospects within the country and aboard, Telangana Government after careful examination hereby order to introduce a new scheme of “Mahatma Jyothiba Phule BC Overseas Vidya Nidhi”.

“Mahatma Jyothiba Phule BC Overseas Vidya Nidhi” will be initially sanctioned to (300) BC Students every year and is open to all eligible graduates to pursue Post Graduate studies abroad subject
             </p>
            </div>
          </div>
        </div>
      </div>

               

      
           
        <div className="py-5">
            <FaqVisa></FaqVisa>
        </div>

    </div>
   </div> 
  );
};

export default Scholarship;
