import React from 'react';
import './Singapore.css'; 
import SingaporeImage from "../../../Images/singapore.jpg";
import TGImage from '../../../Images/tggovt.png'
import FaqVisa from './FaqAccordion/FaqAccordion';
import FaqMalaysia from './FaqAccordion/FaqAccordion';
import FaqSingapore from '../Australia/FaqAccordion/FaqAccordion';

const Singapore = () => {
  return (

    <div className='singapore-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Singapore</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={SingaporeImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">Singapore </h5>
              
              <p className="card-text">
              <span className='first-letter'>  S </span>   ingapore is amongst the most developed countries in the world. It’s a Hub for Global business, finance and logistics. Its standings include: the most “technology-ready” nation (WEF), top International-meetings city (UIA), city with “best investment potential” (BERI), second-most competitive country, third-largest foreign exchange market, third-largest financial centre, third-largest oil refining and trading centre, and the second-busiest container port. Singapore ranks 5th on the UN Human Development Index and the 3rd highest GDP per capita. It is ranked highly in education, healthcare, life expectancy, quality of life, personal safety, and housing. Singapore has also made its name in the field of education as is among the most popular educational destinations. The reasons students chose Singapore are:
             </p>
             
             <p>
             <strong> EXCELLENT EDUCATION SYSTEM </strong>
             Singapore has an excellent well defined education system from Primary school to Post Doctoral studies. It ensures flexibility and opportunities for practical work as part of education.</p>

             <p>
             <strong>COMPARITEVLY CHEAPER THAN USA/UK/AUSTRALIA </strong>
             Tuition fee is comparatively cheaper as compared to USA/UK and Australia.              </p> 
                
              <p>
                <strong>LANGUAGE OF INSTRUCTION : </strong>
              English is the language of instruction which makes its easy for Indian Students.
              </p>
             
            </div>
          </div>
        </div>
      </div>



    
    

               

      
           
        <div className="py-5">
            <FaqSingapore></FaqSingapore>
        </div>

    </div>
   </div> 
  );
};

export default Singapore;
