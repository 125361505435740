import React from 'react';
import './VisaMock.css'; 
import VisaImage from "../../../Images/visa.webp"
import FaqVisa from './FaqAccordion/FaqAccordion';

const VisaMock = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Visa Guidance/ Mock Interviews</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={VisaImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Visa Guidance/ Mock Interviews</h5>
              <p className="card-text">
              After receiving the confirmation of admission, the student needs to apply for Study Visa/ Permit to the respective embassies. We at EDEX facilitate the same by providing:                 </p>
              <p className="card-text">
                <strong>GUIDELINES OF FINANCIAL REQUIREMENTS:
                </strong> Every Country has their specific requirements for living and tuition expenses. We at EDEX ensure that a properexplanation for the same is given to the students and his sponsors. We also verify the documents to ascertain its authenticity and ensure its adhere to the visa requirements. </p>
            </div>
          </div>
        </div>
      </div>

      
           
        <div className="py-5">
            <FaqVisa></FaqVisa>
        </div>

    </div>
   </div> 
  );
};

export default VisaMock;
