import React from 'react';
import './Australia.css'; 
import AustraliaImage from "../../../Images/australia.jpg";
import TGImage from '../../../Images/tggovt.png'
import FaqVisa from './FaqAccordion/FaqAccordion';
import FaqMalaysia from './FaqAccordion/FaqAccordion';
import FaqAustralia from '../Singapore/FaqAccordion/FaqAccordion';

const Canada = () => {
  return (

    <div className='australia-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Australia</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={AustraliaImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">Australia </h5>
              
              <p className="card-text">
              <span className='first-letter'>  A </span>   ustralia is the third most popular destination for International Students. Apart from the fact that it is home to 8 of top 100 Universities in world, 5 of the top 30 cities in the world, 9th in terms of university ranking and gets $ 2,00,000,000 in Annual Government for international students, these are few more points why students choose Australia: Quality of Education/ Global Recognition: Australian degrees are recognized world over and have a very high standing of the Australian Education System. The initiatives taken by Australian Government to regulate and maintain high standards across all the institutions has had a visible effect. All the courses have to be certified and approved before being offered to international students.             </p>
             
             <p>
             <strong> WIDE RANGE OF COURSE: </strong>
             Australian Institutions offers PHd, Masters, Bachelors & Vocational courses covering all range of specializations. There are multiple institutions offering programs in Arts & Sciences, Business, Engineering, etc Australia is recognized as a world leader in many disciplines.</p>

             <p>
             <strong>Technology and Innovation </strong>
             Australian universities have developed a reputation for adaptation of the latest technologies for use in the classrooms. This has resulted in world class facilities and well equipped labs and classrooms. The penetration of Hi Speed internet is among the highest in Australia.             </p> 
                
              <p>
                <strong>LANGUAGE OF INSTRUCTION : </strong>
              English is the language of instruction which makes its easy for Indian Students.
              </p>
             
            </div>
          </div>
        </div>
      </div>



    
    

               

      
           
        <div className="py-5">
            <FaqAustralia></FaqAustralia>
        </div>

    </div>
   </div> 
  );
};

export default Canada;
