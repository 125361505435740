import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqAustralia() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 card-text mb-6' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>Benefits of studying in Australia</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Australian Qualification Framework [AQF]: </Accordion.Header>
                    <Accordion.Body >
                        <p>All courses being offered to International Students are covered under AQF, which is recognized by the Australian Government. Australian Government Training Framework has been setup by Australian Government to provide protection for International Students through ESOS (Education Services for Overseas Students).</p>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Multiculturalism </Accordion.Header>
                    <Accordion.Body>
                    Australia is a multicultural society with immigrants from more than 200 countries. This has made the Australian Society extremely receptive to adapting and accommodating to the international culture. It’s a great advantage for international students and helps them to settle more easily.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Visa Regulations</Accordion.Header>
                    <Accordion.Body>
                    Unlike other countries like USA and Canada, Australia has a very transparent process and rules have been clearly laid out. This ensures complete justice to genuine visa applicants.  </Accordion.Body>
                </Accordion.Item>

               

                <Accordion.Item eventKey='4' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Part-time jobs / Cost-of-living </Accordion.Header>
                    <Accordion.Body>
                    International students are allowed to work part time while they study, which helps them to lower the cost of studying in Australia. Also, standard of living in Australia is amongst the best in the world.</Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey='5' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Climate </Accordion.Header>
                    <Accordion.Body>
                    Climate of Australian is very similar to India and students won’t have many issues adjusting to it. Weather can be bit extreme in countries like Canada or USA.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='6' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Sports and Culture </Accordion.Header>
                    <Accordion.Body>
                    Australia and India are connected very strongly through Cricket. Any avid cricket lover would cherish eating a match at MCG or SCG or be a part of the college cricket team.</Accordion.Body>
                </Accordion.Item>

                

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqAustralia;