import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqCanada() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 card-text mb-6' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>Benefits of studying in Canada</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Language Education</Accordion.Header>
                    <Accordion.Body >
                        <p>Canada is a bilingual country for which teaching their official languages (French and English) as second languages forms an integral part of their curriculum. This helps students to upgrade their language skills for either of the language as they get ahead with their study program</p>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Liberal Immigration </Accordion.Header>
                    <Accordion.Body>
                    International students with Canadian credentials and work experience may apply for citizenship of Canada without leaving Canada.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Learning and Leisure</Accordion.Header>
                    <Accordion.Body>
                    Activities such as sports, art galleries etc within the campus, adds a tint of excitement to the students overall study experience in Canada.    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='3' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Work Possibility </Accordion.Header>
                    <Accordion.Body>
                    International students may work on-campus without a work permit. Study permit holders in Canada may gain work experience by working off campus while their studies. As of June 1, 2014, student may qualify to work off campus up to20 hours per week without a work permit.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='4' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Multicultural Society and Pleasant Destination </Accordion.Header>
                    <Accordion.Body>
                    Canada is a democratic country. It encourages multicultural society through Canadian constitution and the Canadian charter of rights and freedom, the two government bodies strive to preserve the rights of the people living in the country. All are equal and everyone has the right to be socially, culturally and economically active. People there, are readily accepting and respectful of the differences.</Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey='5' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Focus on International Students/Clarity on Visa Rules </Accordion.Header>
                    <Accordion.Body>
                    Canada’s International Education Strategy recently announced its goal to double the number of full-time international students to 450,000 by 2022.The Canadian Council of Ministers of Education prioritizes attracting international students in all education sectors through a number of strategies. The plan is not only focused on recruiting, but also on retaining after graduation by offering more opportunities for Canadian students to work abroad while studying and remain in the country as permanent residents afterward.</Accordion.Body>
                </Accordion.Item>



           

                

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqCanada;