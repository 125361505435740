import React from 'react';
import './Admissions.css'; 
import AdmissionImage from '../../../Images/admissions.png'
import FaqAdmissions from './FaqAccordion/FaqAccordion';

const Admissions = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'> Admissions </h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={AdmissionImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Admissions </h5>
              
              <p className="card-text">
              Entrance Tests [GRE/GMAT/SAT/TOEFL/IELTS]: Provide information to student about English Proficiency Requirements [TOEFL/IELTS/PTE] and cut offs for other Exams [GRE/GMAT/SAT].
             </p>

             <p className="card-text">
              <strong>ACADEMIC PERCENTAGES:</strong>
             Institution-wise requirements for percentages along with accepted backlogs.</p>

             <p className="card-text">
              <strong>DEADLINES FOR ADMISSIONS:</strong>
             Deadlines for Spring/Summer/Fall intakes. Some Universities have multiple deadline i.e. Scholarship/Priority deadline and documents deadline. It’s important to follow the deadlines strictly.</p>  
              

            </div>
          </div>
        </div>
      </div>





               

      
           
        <div className="py-5">
            <FaqAdmissions></FaqAdmissions>
        </div>

    </div>
   </div> 
  );
};

export default Admissions;
