import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';
import EuropeCounrtiesList from '../../../../Images/EuropeCountriesList.png'

function FaqEurope() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 card-text mb-6' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>More Facts about Europe</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Click to Expand</Accordion.Header>
                    <Accordion.Body >
                        <p>Students mainly look for such university where they can apply without appearing to any of entrance exam like IELTS, GRE, TOFEL, and GMAT.

Europe is fascinating, culturally rich country with an extraordinary history. The quality of Education is very high comparing other countries and that too with affordable tuition fees.

In order to get offer letter from University student has just face Skype Interview through Skype communicator with admission officer of University. The final decision of acceptance belongs to University authority.

It’s true that you can apply to good University in Europe without giving this entrance but you should have minimum good command on English. You should able to understand and speak English as intermediate user.

Check also – Free education in Europe

Europe provides you the golden opportunity to make your career without giving any such entrance exam and get yourself enrolled in good and triple accredited University which also provide practical internship options

To get admission in such highly accredited University without giving IELTS hurry up &amp; get yourself enrolled before the deadline arise, Apply Now to study abroad and also get free counselling from experienced counsellors.</p>
                        </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Countries we offer in Europe </Accordion.Header>
                    <Accordion.Body>
                        <ul>
                       <li> Georgia</li>
                       <li>Cyprus</li>
                       <li>France</li>
                       <li> Italy </li>
                        <li> Spain</li>
                        <li>Germany</li>
                         <li>Sweden</li>
                         <li> Ireland </li>
                         <li>Poland</li>
                         <li>Switzerland</li>
                          <li>Latvia</li>
                           <li>Ukraine</li>
                           <li>Austria</li>
                            <li>Czech Republic</li>
                             <li>Lithuania</li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

             

               

             
                

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqEurope;