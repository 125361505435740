import React from 'react';
import './Europe.css'; 
import EuropeImage from "../../../Images/europe.png";
import FaqEurope from './FaqAccordion/FaqAccordion';

const Europe = () => {
  return (

    <div className='europe-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Europe</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={EuropeImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">Europe </h5>
              
              <p className="card-text">
              <span className='first-letter'>  A </span>  pplying to any University of Abroad is the toughest part to overcome the admission in it is IELTS or TOFEL exam requirement. International students who are from non – English speaking native countries it difficult for them to clear this exam and get admission. But don’t worry in Europe there are many University in which you can apply without giving suchEnglish entrance exam. Poland provides the opportunity to International to study without having score of IELTS. This opportunity is available in countries of Europe like France, Poland, Latvia and many more.       </p>
             
             <p>
             <strong> UNIVERSITIES </strong>
            There are eight public universities in New Zealand, which offer degrees are teaching and research-based.</p>

             <p>
             <strong>INSTITUTES OF TECHNOLOGY AND POLYTECHNICS</strong>
             Courses are usually vocational and skills-based, ranging from certificate level through to bachelor degree and postgraduate level.     </p> 
                
              <p>
                <strong>PRIVATE TERTIARY AND TRAINING INSTITUTIONS </strong>
                Offer professional certificates, diplomas and degrees in a diverse range of subjects. More than 800 such establishments are registered with the New Zealand Qualifications Authority.
              </p>
             
            </div>
          </div>
        </div>
      </div>


      




    

               

      
           
        <div className="py-5">
            <FaqEurope></FaqEurope>
        </div>

    </div>
   </div> 
  );
};

export default Europe;
