import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqSingapore() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 card-text mb-6' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>Benefits of studying in Singapore</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Student Safety</Accordion.Header>
                    <Accordion.Body >
                        <p>Singapore is among the safest countries in the world with no instances of racism or any other form of discrimination or violence.</p>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Bussiness Hub </Accordion.Header>
                    <Accordion.Body>
                    Singapore is the headquarters for many of the top MNC’s of the world. This provides a very good opportunity for students to do internship with them and learn the best practices.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Multicultural Society</Accordion.Header>
                    <Accordion.Body>
                    Singapore is home to people of almost all nationalities. Its a cultural hub and great way to learn about other cultures, its makes
you a global citizen    </Accordion.Body>
                </Accordion.Item>

               

                <Accordion.Item eventKey='4' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Recognition and value of the degree </Accordion.Header>
                    <Accordion.Body>
                    Degree from Singapore is recognized world over due to its quality and previous success stories.</Accordion.Body>
                </Accordion.Item>

                

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqSingapore;