import React from 'react';
import './AdmissionProcess.css'; 
import AdmissionProcessImage from "../../../Images/AdmissionProcess.webp"

const AdmissionProcess = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Application Process</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={AdmissionProcessImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Application Process</h5>
              <p className="card-text">
                <strong>Application Process:</strong> APPLICATION PROCESS
                We are committed to provide transparency and ensure that the students are part of the process. The various steps involved in the process are mentioned below:</p>
              <p className="card-text">
                <strong>ONLINE APPLICATIONS:
                </strong> Filling online application form, it mainly deals with personal and academic details of the student.          </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Despatch of Documents to the University</h5>
              <p className="card-text">
               We need to send the documents as per the university i.e either soft copy by email or hard copies
                          </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Official Score Reporting
              [GRE/GMAT/SAT/TOEFL/IELTS/PTE]</h5>
              <p className="card-text">
                Most of the institutions ask for official reporting of the scores from examination authority. Edex guides the students through various steps which is online for GRE/GMAT/SAT/TOFEL/PTE and Paper base for IELTS

   </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Application Followup</h5>
              <p className="card-text">
                Edex ensures that we are in constant touch with the admission departments. To ensure that there isn't any delay in the process. Students are kept in a loop through our software and regular updates throught phone/emails and SMS. We endeavour to fulfil all requirements of universities and any additional documents in shortest possible time to facilitate fastest possible collage
              
 </p>
            </div>
          </div>
        </div>
  
      </div>

      

    </div>
   </div> 
  );
};

export default AdmissionProcess;
