import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Australia from '../../Images/australia.jpg';
import CanadaImage from '../../Images/canada.jpg';
import Uk from '../../Images/uk.jpg';
import NewZealand from '../../Images/newzeland.jpg';
import Singapore from '../../Images/singapore.jpg';
import Malaysia from '../../Images/malaysia.webp';
import EuropeImage from "../../Images/europe.png";
import {useNavigate} from 'react-router-dom'



const blogs = [
    {
        id: 1,
        img: [Australia],
        title: 'Australia',
        description: 'Study in Australia',
        navroute:'/australia'
    },
    {
        id: 2,
        img: [CanadaImage],
        title: 'Canada',
        description: 'Study in Canada',
        navroute:'/canada'
    },
    {
        id: 3,
        img: [Uk],
        title: 'United Kingdom',
        description: 'Study in UK',
        navroute:'/uk'
    },
    {
        id: 4,
        img: [NewZealand],
        title: 'New Zealand',
        description: 'Study in New Zealand',
        navroute:'/nz'
    },
    {
        id: 5,
        img: [Singapore],
        title: 'Singapore',
        description: 'Study in Singapore',
        navroute:'/singapore'
    },
    {
        id: 6,
        img: [Malaysia],
        title: 'Malaysia',
        description: 'Study in Malaysia',
        navroute:'/malaysia'
    },
    {
        id: 7,
        img: [EuropeImage],
        title: 'Europe',
        description: 'Study in Europe',
        navroute:'/europe'
    },

];

function Blog() {

  const navigator = useNavigate();

  return (
    <div className='blog-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Study Abroad</h1>
                <p className='text-center w-75 mb-5'></p>
            </div>
        </header>

        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <div className="row g-4">
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4' onClick={()=>{navigator(blogs[blog.id-1]['navroute'])}}>
                            <Link to="/blog" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                        <Card.Title className='fs-2 mb-4'>{blog.title}</Card.Title>
                                        <Card.Text className='text-center'>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}


function ChooseYourFavDest(){
    const navigator = useNavigate();

    return (
      <div className='blog-page'>
          

            <h1  style={{color:"darkgreen",textAlign:'center'}}>Countries</h1>
            <h4 style={{textAlign:'center'}}>Choose Your Favourite Destination!</h4>
  
          <div className='bg-body-tertiary py-5'>
              <div className="container">
                  <div className="row g-4">
                      {blogs.map((blog) => (
                          <div key={blog.id} className='col-md-6 col-lg-4' onClick={()=>{navigator(blogs[blog.id-1]['navroute'])}}>
                              <Link to="/blog" className='text-decoration-none'>
                                  <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
                                      <Card.Img variant="top" src={blog.img} />
                                      <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                          <Card.Title className='fs-2 mb-4'>{blog.title}</Card.Title>
                                          <Card.Text className='text-center'>{blog.description}</Card.Text>
                                      </Card.Body>
                                  </Card>
                              </Link>
                          </div>
                      ))}
                  </div>
              </div>
          </div>
      </div>
    )
  }




export {ChooseYourFavDest}


export default  Blog





