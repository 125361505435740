import React from 'react';
import './Uk.css'; 
import UkImage from "../../../Images/uk.jpg";
import TGImage from '../../../Images/tggovt.png'
import FaqVisa from './FaqAccordion/FaqAccordion';
import FaqMalaysia from './FaqAccordion/FaqAccordion';
import FaqUk from './FaqAccordion/FaqAccordion';

const Uk = () => {
  return (

    <div className='singapore-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>United Kingdom</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={UkImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">United Kingdom </h5>
              
              <p className="card-text">
              <span className='first-letter'>  E </span> xcellent Academics: Ranking second among the most popular study destinations internationally, the institutions in UK are governed by the official bodies such as the Quality Assurance Agency which consistently strive to maintain the steadfast tradition of world-class standard of teaching. Shorter Duration Programs: Master degree typically lasts 12 months in UK and undergraduate programs in England and Wales take 3 years, whereas in Scotland they take four years to complete. UK institutions offer flexibility to blend academic and vocational courses which can be tailored according to individual’s interest. </p>
             
             <p>
             <strong> LOWER COSTS OF EDUCATION: </strong>
             Sowing to UK’s shorter durations of courses, the tuition fee cost per year, (a major concern for international students), can be significantly lower. Besides, by saving a year the student may enjoy the advantage of an early start in his/her career or/and start working rather.</p>

             <p>
             <strong> RESEARCH: </strong>
             5 per cent of the world’s scientific research is carried out by the UK and produces 14 per cent of the world’s most frequently cited papers.            </p> 
                
           
             
            </div>
          </div>
        </div>
      </div>



    
    

               

      
           
        <div className="py-5">
            <FaqUk></FaqUk>
        </div>

    </div>
   </div> 
  );
};

export default Uk;
