import React from 'react';
import './Malaysia.css'; 
import MalaysiaImage from "../../../Images/malaysia.webp";
import TGImage from '../../../Images/tggovt.png'
import FaqVisa from './FaqAccordion/FaqAccordion';
import FaqMalaysia from './FaqAccordion/FaqAccordion';

const Malaysia = () => {
  return (

    <div className='malaysia-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Malaysia</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={MalaysiaImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">Malaysia </h5>
              
              <p className="card-text">
              <span className='first-letter'>  S </span>   tudy in Malaysia Study in Malaysia, choose from a number of local universities. You can also join the satellite campuses of leading international universities and study there. Study in Malaysia for Indian students Malaysia is a country of diversity. It is home to numerous ethnic groups, races and religions. Its geographical location is almost as diverse as its culture. Malaysia, known as the gateway to Asia, has just about the right balance of city life and nature. From Kuala Lumpur’s Petronas Twin Towers to rainforests, Malaysia has a bit ofeverything.
             </p>
             
             <p>
             <strong> UNIVERSITIES </strong>
             There are eight public universities in New Zealand, which offer degrees are teaching and research-based.</p>

             <p>
             <strong>INSTITUTES OF TECHNOLOGY AND POLYTECHNICS </strong>
             Courses are usually vocational and skills-based, ranging from certificate level through to bachelor degree and postgraduate level.
              </p> 

             
            </div>
          </div>
        </div>
      </div>



      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-0">
          </div>
          <div className="col-md-12">
            <div className="card-body">
              
              <p className="card-text">
              <p><strong>PRIVATE TERTIARY AND TRAINING INSTITUTIONS : </strong>
              Offer professional certificates, diplomas and degrees in a diverse range of subjects. More than 800 such establishments are registered with the New Zealand Qualifications Authority.</p>         
                          </p>

                         <strong> EDUCATION SYSTEM IN MALAYSIA :  </strong>
The higher education system is governed by the Malaysian Ministry of Education. There are two types of institutions: colleges and universities. Malaysia is also well known for being home to branch campuses of foreign institutions at affordable tuition rates. Examples of such universities are Monash University, Swinburne University of Technology, Curtin University of Technology, University of Nottingham, Raffles Design Institute and
SAE Institute.     

           <ul>
            <li><strong>Colleges</strong> : Colleges in Malaysia mostly function as independent institutions, and they do not have any university statuses. Colleges are meant for transiting into the university level of education. Hence, most Malaysian colleges offer undergraduate degrees or bridge courses for students.</li>
            <li><strong>Universities</strong> : A number of universities in Malaysia offer undergraduate and postgraduate degrees. In addition, parent campuses of some of the top universities in countries like the UK, Australia and Ireland have established branch campuses in Malaysia. Branch campuses offer an equivalent degree to students, similar to what they offer at parent campuses, but at an affordable cost.</li>

           </ul>

            </div>
          </div>
        </div>
      </div>

               

      
           
        <div className="py-5">
            <FaqMalaysia></FaqMalaysia>
        </div>

    </div>
   </div> 
  );
};

export default Malaysia;
