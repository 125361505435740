
import React from 'react';
import './Aut.css';
import { Link } from 'react-router-dom';
import AutImg from "../../../Images/Aut.jpg"
import ChooseSection from '../../../components/ChooseSection/ChooseSection';
import AutGallery from '../../../Images/image.png'





function AUT() {
    return (
      <div className='about-page'>
          <header className='height-75'>
              <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                               
                  <h1 className='text-center fw-semibold'>AUT</h1>
              
                  <p className='text-center w-75 mb-5'>Why study at AUT as an international student ? </p>
  
              </div>
          </header>
  
          <div className='container my-5'>
              <div className="row">
                  <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                      <h2 className='mb-4 mb-lg-5'>More About AUT</h2>
                      <p>Studying at AUT as an international student is a great choice. Ranked in the top 1% of universities in the world, AUT is a modern and innovative university located in the centre of Auckland, New Zealand’s biggest city. We focus on helping our students find their greatness and prepare them for a bright future. </p>
                      <p>AUT’s mission is to create great graduates, and each member of our staff contributes to this. We offer exceptional learning experiences that prepare students to be successful wherever in the world their career may take them. </p>
                      <p>Study in the heart of Auckland </p>
                       <ul>
                        <li>AUT is located in Auckland, New Zealand’s economic hub and largest city </li>
                        <li>Three modern campuses across Auckland; all with world-class facilities</li>
                        <li>Minutes away from restaurants, cafés, bars and shopping centres in the Auckland city centre</li>
                        <li>Hop on a ferry to experience the beautiful Waitemata Harbour or swim at the safe city beaches close by</li>
                       </ul>
                      <Link to="/contact">
                          <button type='button' className='btn  btn-lg mx-0 mx-sm-2 my-2 my-sm-0' style={{backgroundColor:'lightgreen',color:'darkgreen'}}>Contact Us</button>
                      </Link>
                  </div>
                  <div className='col-lg-6 d-flex justify-content-center'>
                      <img src={AutImg} className='img-fluid w-100' alt="" style={{borderRadius:30}} />
                  </div>
              </div>
              <div className='row'>
                
              <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'></div>


              <div className='col-lg-6 d-flex justify-content-center'>
                      <img src={AutGallery} className='img-fluid h-100 w-100' alt="" />
                  </div>
              </div>
          </div>

           <div className='row'>



                 <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'></div>


                  <div className='col-lg-6 d-flex justify-content-center'>

                  <ul>
                  <p>One of the world’s leading modern universities </p>

                        <li>Highly ranked for the quality of our teaching and learning and the top 50 universities under 50 years of age</li>
                        <li>Courses that encourage innovation, entrepreneurship, problem-solving and connections with industry</li>
                        <li>90% of bachelor’s degree students complete workplace experience as part of their studies, which often leads to their career start in New Zealand</li>
                        <li>Stand out to employers through our employability workshops, networking events, and the AUT Edge and Beyond Awards </li>
                        <li>A supportive university culture that celebrates diversity </li>
                        <li>Ranked first in New Zealand for international outlook</li>
                        <li>Over 100,000 alumni around the world, with AUT graduates in demand in every sector</li>
                        <li>A wide range of student support services, including dedicated support for international students</li>
                        <li>We have an overall five-star rating from university rankings organisation QS for Teaching, Employability, Research, Internationalisation, Facilities, Innovation, Inclusiveness, Sports (specialist subject) </li>
                    </ul>
                  </div>


           </div>
         <div className='container my-5 ' >
           <div className='row'>
           <ul>
                  <p>A strong research Culture</p>

                        <li>Ranked first in Australasia for global research impact (Times Higher Education) </li>
                        <li>60+ research centres and institutes delivering leading research </li>
                        <li>World-leading academic staff who are known as experts in their field </li>
                        <li>Research strengths include sport science, public health, biomedical technology, radio astronomy and space science, and tourism and hospitality</li>
                        <li>Our staff have collaborated on research projects with 140 different countries and worked with more than 5,000 industry partners around the world</li>
                        <li>Ranked first in New Zealand for international outlook</li>
                        <li>Our research also feeds back into the classroom, and students can contribute to and learn from these research discoveries</li>
                    </ul>

           </div>
        </div>

        

  
          <div className='bg-dark text-light py-5'>
              <ChooseSection />
          </div>
                     
          
  
            
  
  
  
  
          
      </div>
    )
  }
  

  export default AUT