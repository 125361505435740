import React from 'react';
import './CareerCounselling.css'; 
import './scroll.css'
import CareerCounsellingImg from "../../../Images/careercouselling.png"
import Uni1 from "../../../Images/uni1.jpg"
import Uni2 from "../../../Images/uni2.jpg"
import Uni5 from "../../../Images/uni5.jpg"
import Uni4 from "../../../Images/uni4.jpg"
import Uni6 from "../../../Images/uni6.jpg"
import Uni7 from "../../../Images/uni7.jpg"



const CareerCounselling = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Career Counselling</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={CareerCounsellingImg} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Career Counselling</h5>
              
              <p className="card-text">
              Most of the students are in a dilemma about various career options to choose especially after intermediate and under graduation. We have made an exhaustive list of course for most of the Qualifications &amp; specializations Click Here. We provide the information and compare various countries based on Quality of Study/ Programs Available/ Future Prospects/ Costs / Recognition of Degree etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{alignContent:'center',alignSelf:'center',alignItems:'center'}}>
        <div className="col-md-12">
          <div className="card mb-12">
            <div className="card-body">
              <h5 className="card-title">Learn From the best</h5>
              <p className="card-text">
              The qualifications you’ll gain in New Zealand are highly regarded and sought after by employers and other educational institutions around the world. When you study in New Zealand, you learn beyond your subject.
              </p>
            </div>
          </div>
        </div>
        
        </div>


        <div class="logos">
      <div class="logos-slide">

        <img src={Uni1} style={{height:200,width:250}} />
        <img src={Uni2} style={{height:200,width:250}} />
        <img src={Uni4} style={{height:200,width:250}} />
        <img src={Uni5} style={{height:200,width:250}} />
        <img src={Uni6} style={{height:200,width:250}} />
        <img src={Uni7} style={{height:200,width:250}} />

      </div>
      </div>


    </div>
   </div> 
  );
};

export default CareerCounselling;
