import React from 'react';
import './UniversitySelection.css'; 
import UniversitySelectionImage from "../../../Images/UniversitySelection.jpg"

const UniversitySelection = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>University Selection</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={UniversitySelectionImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Education Level</h5>
              <p className="card-text">
                <strong>RANKING OF INSTITUTION:</strong> Although the ranking methodology varies and it differs as per the ranking institution, it does provide an insight into the quality of Institution. We always keep it in our foresight while selecting the Universities.</p>
              <p className="card-text">
                <strong>ACCREDITATION OF THE INSTITUTION:
                </strong> This is amongst the most important factor in selection of the universities. It reflects the quality of institution and recognition of degree. EDEX ensures that we apply to only recognized, well accredited institutions.            </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">ACADEMIC PERCENTAGE[Last Qualification]</h5>
              <p className="card-text">
              A very important factor in selection of university, as it reflects the performance of the student in his area of study. Most of the universities focus on this as this is the
              foundation based on which they would base their further studies.              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Scores in Entrance Exams
              [GRE/GMAT/SAT/TOEFL/IELTS/PTE]</h5>
              <p className="card-text">
              There are 2 types of Entrance exams 1) English Proficiency [TOEFL/ IELTS/ PTE] 2) Other Prerequisite Exams like GRE/ GMAT/ SAT. Most of the Universities have very strict conditions for English requirements and would offer English classes in cases of low core. However, GRE/GMAT/SAT requirement is taken in conjunction with Academic scores.              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Budget</h5>
              <p className="card-text">
              Before selection of the schools we normally speak to student's parents to get an
estimate about the tuition fee budget. While short listing we ensure that we adhere to
the budget.              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Location</h5>
              <p className="card-text">
              We ask the students if they have a specific location choice in the chosen country. We
              try to select universities in the vicinity of the location.              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">OPPORTUNITIES FOR SCHOLARSHIPS/ASSISTANTSHIPS:
          </h5>
          <p className="card-text">
          Many universities offer Scholarships/ Assistantships/Tuition Waiver for the students. We always inform the same to the students and inform them about the procedures for it.          </p>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">CAMPUS SAFETY:
          </h5>
          <p className="card-text">
          Safety is paramount and a major worry for students and parents. We ensure that all the City, university and nearby areas are safe and don’t have any history of violence.

</p>
        </div>
      </div>


      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">INTERNSHIPS:          !</h5>
          <p className="card-text">
          Internships gives the students to get real time work experience in foreign country the student’s areas of expertise. Many universities have a tie-up with industry wherein they collaborate to provide the same.</p>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">OPPORTUNITIES FOR PART TIME JOBS:    </h5>
          <p className="card-text">
          Most of the students look for an opportunity to work part-time while studying. Part time is allowed only on campus in USA, so we look for campuses which are large enough to have opportunities for on campus jobs. Other Countries offer off-campus jobs, so ideally we focus on locations which have opportunities for it.</p>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">FEEDBACK FROM EXISTING STUDENTS AND ALUMNI:          </h5>
          <p className="card-text">
          It’s always the best way to know firsthand about a particular institution. We ensure that we are in touch with existing students who are studying there and give real-time feedback to prospective students.
          </p>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h5 className="card-title">WEATHER </h5>
          <p className="card-text">
          We provide the information about the prevailing weather conditions over there as many students aren’t able to tolerate extreme cold.
          </p>
        </div>
      </div>


    </div>
   </div> 
  );
};

export default UniversitySelection;
