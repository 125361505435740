import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqScholarship() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5' style={{color:'darkgreen'}}><i>Mandatory Requirements</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Click to expand Requirements list:</Accordion.Header>
                    <Accordion.Body >
                        <ul>
                            <li>He / She should have a valid TOEFL / IELTS & GRE / GMAT.</li>
                            <li>He / She should have obtained admission in an accredited Foreign University.</li>
                            <li>He / She should possess a valid Passport.</li>
                            <li>The Candidate should make his/her own efforts in seeking admission in an accredited University / Institution Abroad</li>
                            <li>The selected candidate has to join the concerned University within one year of communication of selection. On expiry of this specified period of time, the
                            award will be automatically cancelled and come to an end. No request for extension of time for availing the award is permissible under the Scheme.</li>
                            <li>The candidate shall not change the course of study for which the scholarship has been sanctioned.</li>
                            <li>It will be the responsibility of the candidate to obtain the appropriate visa for a country wherein one intends to study further under award from the
Scheme and the Visa issuing authorities may kindly see that only such type of Visa be issued which only permits the candidate to pursue specified course in
abroad and thereafter the candidate returns to India.</li>
                             <li>The applications should be complete in all respects and should be accompanied by all relevant documents. The applications, incomplete in any respect, are
                             liable to be rejected.</li>
                             <li>Scholarship Amount:</li>
                             <li>Fees: Fees shall be payable in two instalments as follows:</li>
                             <li>Instalment-I: An amount of 10.00 Lakhs shall be payable to the Institution /students upon production of the landing permit / I-94 card (ImmigrationCard).</li>
                             <li>Instalment-II: An amount of 10.00 Lakhs shall be payable to the Institution /students upon production of the 1st Semester results.</li>
                             <li>Sanction Proceedings specifying the amount of 20.00 Lakhs will be issued to the individual towards fees and living expenses by way of a grant subject to
his/her joining into the Course to the Candidates selected by the Selection Committee. The sanction proceedings can be used by the Candidates for
obtaining a Visa from the respective Embassy.</li>
                              <li><strong>Education Loan : </strong>
                                  The Student shall be eligible for an educational loan of 10.00 Lakhs from any nationalized bank at prevailing rates of interest.</li>
                        </ul>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Age </Accordion.Header>
                    <Accordion.Body>
                    The Maximum age under the scheme shall not exceed 30 years as on 1st July of the year of advertisement.
</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Eligibility Income</Accordion.Header>
                    <Accordion.Body>
                    BC Students whose family income is less than “Rs.1,50,000/- in Rural Areas and Rs.2,00,000/- in Urban Areas.” Per annum from all sources are eligible, In case of employees, salary certificate from the employer is a must. In all cases the income certificate must be obtained through MEE SEVA. A copy of the latest tax assessment as well as the latest monthly salary slip from the employer is also required to be enclosed with the application.
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='3' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Qualification</Accordion.Header>
                    <Accordion.Body>
                    60% marks or equivalent grade in foundation Degree in Engineering / Management / Pure Sciences / Agriculture Sciences /Medicine Nursing / Social Sciences / Humanities.                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='4' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>One Child in a family award </Accordion.Header>
                    <Accordion.Body>
                    Not more than one child of the same parents / guardian’s will be eligible and to this effect a self certificate will be required from the candidate. The awardees cannot be considered for the second or subsequent times as the individual can be awarded only once in a life time.</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey='5' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Countries Eligible under the Scheme </Accordion.Header>
                    <Accordion.Body>
                    USA, UK, Australia, Canada and Singapore.
                    </Accordion.Body>
                </Accordion.Item>

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqScholarship;