import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import StartCoursesImg from '../../utils/images/start-courses-img.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { Card } from 'react-bootstrap';
import Blog1Img from '../../utils/images/blog1-img.jpg';
import Blog2Img from '../../utils/images/blog2-img.jpg';
import Blog3Img from '../../utils/images/blog3-img.jpg';
import { hover } from '@testing-library/user-event/dist/hover';
import Blog, { ChooseYourFavDest } from '../Blog/Blog';
import Ieltsimg from "../../Images/ielts.png";
import pteimg from "../../Images/pte.png";
import  asaimg      from "../../Images/asiaeuniversity.png";
import malaysiamba from "../../Images/mbamalaysia.png";
import './scroll.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Uni1 from  "../../Images/uni1.jpg"
import Uni2 from "../../Images/uni2.jpg"
import Uni5 from "../../Images/uni5.jpg"
import Uni4 from "../../Images/uni4.jpg"
import Uni6 from "../../Images/uni6.jpg"
import Uni7 from "../../Images/uni7.jpg"

const blogs = [
    {
        id: 1,
        img: [Blog1Img],
        title: 'Blog 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
    },
    {
        id: 2,
        img: [Blog2Img],
        title: 'Blog 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
    },
    {
        id: 3,
        img: [Blog3Img],
        title: 'Blog 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
    }
];

function Home() {
  return (
    <div className='home-page'>
        <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
            <div className='container d-flex flex-column align-items-center'>
                 <br></br> <br></br> <br></br>
                <h2>Welcome To</h2>
                <h1 className='text-center fw-semibold'>Edex Academy USA</h1>
                <h5>"Prince endeavors to fulfill the slogan “Start with a dream…Finish with a future.”</h5>
                <p>EDEX Academy USA Ltd started 2024 provides International Education services to the students desiring to further their educational careers in overseas educational institutions. EDEX has its headquarters in Auckland, New Zealand, and Registered Offices in Malaysia, Sri Lanka, Nepal, UK, USA, Canada, which helps in facilitating the process.</p>
                <div className='d-flex flex-column flex-sm-row align-items-center'>
                    <Link to="/services">
                        <button type='button' className='btn btn-success btn-lg mx-0 mx-sm-2 my-2 my-sm-0' >Our Services</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'  >Contact Us</button>
                    </Link>
                </div>
            </div>
        </header>

        <div className='py-5'>
            <ChooseYourFavDest></ChooseYourFavDest>
        </div>

        <div className='py-5' style={{textAlign:'center'}}>
            <h1>Courses we offer online</h1>
               
            <div class="logos">
      <div class="logos-slide">

        <img src={Ieltsimg} style={{height:200,width:250}} />
        <img src={asaimg} style={{height:200,width:250}} />
        <img src={pteimg} style={{height:200,width:250}} />
        <img src={malaysiamba} style={{height:200,width:250}} />
    

      </div>
      </div>

         <div className='py-5'>
            <h1>Enquire Now</h1>
         <ContactForm></ContactForm>

         </div>



        </div>


        <div className="py-5">
            <ChooseSection />
        </div>

        <div class="logos" style={{textAlign:'center'}}>
        <h1>Learn from the best</h1>

      <div class="logos-slide">

            
        <img src={Uni1} style={{height:200,width:250}} />
        <img src={Uni2} style={{height:200,width:250}} />
        <img src={Uni4} style={{height:200,width:250}} />
        <img src={Uni5} style={{height:200,width:250}} />
        <img src={Uni6} style={{height:200,width:250}} />
        <img src={Uni7} style={{height:200,width:250}} />

      </div>
      </div>


       

        
    </div>
  )
}

export default Home;



function ChooseCourses(){
    <>
  
    <div className='py-5 bg-light'>
    <div className="container">
        <div className='row d-flex align-items-center justify-content-around'>
            <div className='col-lg-5'>
                <h2 className='text-capitalize'>2024 start courses</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, placeat.</p>
                <Link to="/courses">
                    <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Learn More</button>
                </Link>
            </div>
            <div className='col-lg-5 mt-5 mt-lg-0'>
                <img src={StartCoursesImg} className='img-fluid' alt="" />
            </div>
        </div>
    </div>
</div>
       
      



 <div className="py-5">
    <FaqAccordion />
</div>

</>
}



const ContactForm = ()=>{
 
    return(
        <>
               <div className='container my-5 d-flex justify-content-center'>
            <Form id='contact-form'>
                <Row className='mb-3'>
                    <Col sm={12} md={6} className='mb-3 mb-md-0'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control placeholder='First name' />
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control placeholder='Last name' />
                    </Col>
                </Row>

                <Form.Group className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' />
                    <Form.Text className='text-muted'>
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control placeholder='Your address' />
                </Form.Group>

                <Row className='mb-3'>
                    <Col sm={12} md={6} className='mb-3 mb-md-0'>
                        <Form.Label>Location</Form.Label>
                        <Form.Select defaultValue="London">
                            <option>India</option>
                            <option>NewZealand</option>
                            <option>USA</option>
                        </Form.Select>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control placeholder='Postcode' />
                    </Col>
                </Row>

                <Form.Group className='mb-3'>
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group>

                <Button variant="success btn-lg" type='submit'>Submit</Button>
            </Form>
        </div>


        </>
    );
}



function ReadFromBlogsSection(){
    <div className='blog-section text-light py-5'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Latest on the blog</h2>
                <div className='row g-4'>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='col-md-6 col-lg-4'>
                            <Link to="/blog" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect'>
                                    <Card.Img variant="top" src={blog.img} />
                                    <Card.Body className='p-md-5'>
                                        <Card.Title>{blog.title}</Card.Title>
                                        <Card.Text>{blog.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
                <Link to="/blog">
                    <button type='button' className='btn btn-danger btn-lg mt-5'>Read More Blogs</button>
                </Link>
            </div>
        </div>
}