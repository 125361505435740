import React from 'react';
import './Canada.css'; 
import CanadaImage from "../../../Images/canada.jpg";

import FaqCanada from './FaqAccordion/FaqAccordion'


const Canada = () => {
  return (

    <div className='canada-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Canada</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={CanadaImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">Canada </h5>
              
              <p className="card-text">
              <span className='first-letter'>  C </span>   anada has been voted best place to live & best place to study, while also ranking high for working, visiting and investing. Other reasons apart from these are: Internationally Recognized Degree: Canadian graduates are highly sought after by employers due to their high standards of academic excellence. Canadian degree, diploma or certificate is accepted globally owing to its quality education and competitive entry criteria. Affordable Education: The quality of education and living standards in Canada are amongst the highest in the world. Cost of Living and Tuition Fees are much lesser compared to USA and UK.  </p>
             
             <p>
             <strong> WIDE RANGE OF COURSE: </strong>
             Australian Institutions offers PHd, Masters, Bachelors & Vocational courses covering all range of specializations. There are multiple institutions offering programs in Arts & Sciences, Business, Engineering, etc Australia is recognized as a world leader in many disciplines.</p>

             <p>
             <strong>Technology and Innovation </strong>
             Australian universities have developed a reputation for adaptation of the latest technologies for use in the classrooms. This has resulted in world class facilities and well equipped labs and classrooms. The penetration of Hi Speed internet is among the highest in Australia.             </p> 
                
              <p>
                <strong>LANGUAGE OF INSTRUCTION : </strong>
              English is the language of instruction which makes its easy for Indian Students.
              </p>
             
            </div>
          </div>
        </div>
      </div>



    
    

               

      
           
        <div className="py-5">
            <FaqCanada></FaqCanada>
        </div>

    </div>
   </div> 
  );
};

export default Canada;
