
import React from 'react';
import './TravelAssitance.css';
import { Link } from 'react-router-dom';
import AutImg from "../../../Images/Aut.jpg"
import ChooseSection from '../../../components/ChooseSection/ChooseSection';
import AutGallery from '../../../Images/image.png'
import TravelAssitanceImage from '../../../Images/travelAssistance.jpg'




const TravelAssitance = () => {
    return (
  
      <div className='blog-page'>
      <header className='height-75'>
          <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
              <h1 className='text-center fw-semibold'>Travel Assistance            </h1>
          </div>
      </header>
  
  
  
      <div className="container my-5">
        <div className="card mb-4">
          <div className="row no-gutters">
            <div className="col-md-4">
              <img src={TravelAssitanceImage} className="card-img" alt="Education" />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">Travel Assistance </h5>
                
                <p className="card-text">
                After receiving the confirmation of admission, the student needs to apply for Study Visa/ Permit to the respective embassies. We at EDEX facilitate the same by providing:
               </p>

               <p className='card-text'>
               After receiving the confirmation of admission, the student needs to apply for Study Visa/ Permit to the respective embassies. We at EDEX facilitate the same by providing:
                 </p>

               <p className='card-text'>
               <strong>GUIDELINES OF FINANCIAL REQUIREMENTS:</strong>
Every Country has their specific requirements for living and tuition expenses. We at EDEX ensure that a proper explanation for the same is given to the students and his sponsors. We also verify the documents to ascertain its authenticity and ensure its adhere to the visa requirements.
              
               </p>


              </div>
            </div>


          </div>
        </div>


        <div className="card mb-4">
          <div className="row no-gutters">
            <div className="col-md-4">
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title"> Assistance is Filling Visa Forms </h5>
                
                <p className="card-text">
                We assist the students to fill in the visa forms as per the country’s procedure. Some of them have online and others have paper-based forms.               </p>
              </div>
            </div>

           
        </div>
         </div>
       


         <div className="card mb-4">
          <div className="row no-gutters">
            <div className="col-md-4">
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title"> Procedure for Arrangement of Documents: </h5>
                
                <p className="card-text">
                very country has a separate procedure for visas. Some have online submissions, some have personal submissions, and others have email submissions. We guide the students through every step to ensure a hassle-free ETEC process. </p>
              </div>
            </div>

           
        </div>
         </div>


         

         <div className="card mb-4">
          <div className="row no-gutters">
            <div className="col-md-4">
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title"> Mock Interview Sessions </h5>
                
                <p className="card-text">
                As part of the visa process, many embassies conduct interviews via phone, Skype, or in person. At ETEC, we decipher the interview process through carefully programmed sessions. We provide students with requisite tools and information required. Additionally, we conduct mock sessions to enable students to practice their replies and reduce nervousness and anxiety. </p>
              </div>
            </div>

           
        </div>
         </div>

                 
  
        
             
         
  
      </div>
     </div> 
    );
  };

  export default TravelAssitance