import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqUk() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5 card-text mb-6' style={{width:300,color:'white',background:'gray',borderRadius:30}}><i>Benefits of studying in UK</i></h2>
            <Accordion defaultActiveKey="" flush >
                <Accordion.Item eventKey='0'style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header >Improves English Language Skill </Accordion.Header>
                    <Accordion.Body >
                        <p>Studying in UK, the homeland of English ensures students master the universal language which further aids in enhancing his/her career prospects.</p>
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header> Placements </Accordion.Header>
                    <Accordion.Body>
                    Studying in the UK renders students with a global perspective, a quality highly sought after by the employers world-wide, leading to increased chances of employment as well as your potential salary.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Financial Aid</Accordion.Header>
                    <Accordion.Body>
                    Scholarships and grants provided by the Institutions may aid in funding education for international students and also free health care on
                    the British National Health Service. Students might be able to work while study The UK Council for International Student Affairs (UKCISA) could provide any further information required on the same </Accordion.Body>
                </Accordion.Item>

               

                <Accordion.Item eventKey='3' style={{borderRadius:30,backgroundColor:'lightgray'}}>
                    <Accordion.Header>Multicultural & Interesting Destination </Accordion.Header>
                    <Accordion.Body>
                    In the UK, the alumni of the colleges and universities often feature diverse student groups from all over the world, which itself gives a direct evidence of the religious and cultural acceptance of the country.</Accordion.Body>
                </Accordion.Item>

               
            </Accordion>
        </div>
    </div>
  )
}

export default FaqUk;