import React from 'react';
import './EducationLoan.css'; 
import EducationLoanImage from "../../../Images/educationloan.jpg"

const EducationLoan = () => {
  return (

    <div className='blog-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>Education Loan</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-4">
            <img src={EducationLoanImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Education Loan</h5>
              <p className="card-text">
              Education Abroad is an expensive affair and many of the parents look for educational loan to meet the requirements. There is a tax rebate for it and it’s the most hassle free process for showing the funds during visa. The banks have their own checks and balances and are guided by their policies regarding approval of the loans. We at EDEX have tied up with few financial institutions to facilitate faster processing of loans.</p>
                          <p><strong>Note! </strong> EDEX doesn’t guarantee approval of loan nor is involved in the evaluation process. The Banks will decide case by case based on the merits</p>
                          
                          </div>
          </div>
        </div>
      </div>

     


    </div>
   </div> 
  );
};

export default EducationLoan;
