import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import AboutUsSectionImg from '../../Images/about-us.png';
import EdexTeamPicture from '../../Images/edexteam.jpg'
import PrinceKumar from "../../Images/princekumar.jpg"
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import Person1 from '../../Images/veronica.png';
import Person2 from '../../Images/SonuRaj.jpg';
import Person3 from '../../Images/Prakash.jpg';
import Person4 from '../../Images/Solomon.jpg';
import Person5 from '../../Images/Jagan.jpg';



const veronica = {
    id: 1,
    img: [Person1],
    name:"Veronica Samuel",
    role:"CEO",
    email:"veronica.edex@gmail.com",
    mobile:"+91 9603673611",
    bio:"With 25 years of experience in the Marketing and Service Industry, I gained considerable knowledge and managing Edex Academy as a CEO. I oversee the operations of Edex Academy. In particular, marketing and promotions to reach globally. I am happy to lead the team and ensuring that Edex is growing from strength to strength in the last decade.​"

};


const persons = [
    {
        id: 1,
        img: [PrinceKumar],
        name:"Prince Kumar",
        role:"MANAGING DIRECTOR",
        email:"PRINCE.EDEX@GMAIL.COM",
        mobile:"",
        bio:"​"
    },
    {
        id: 2,
        img: [Person2],
        name:"Sonuraj Dandotikar",
        email:"sonuraj.edex@gmail.com",
        mobile:"+91 9666222412",
        bio:"An engineer holds the position of Centre Manager (Client Relations). With over 8 years of experience, he brings enormous business expertise to the role. He has played a pivotal role in extending EDEX’s reach to the verticals of Europe, Malaysia, Singapore and Russia with team management, human resources, communications and liaising with partner universities. His ability to identify opportunities early, taking strategic decisions and rapid implementation capabilities has resulted in EDEX expanding its operations in several key regions in India, broadening its base and making it one of the few organizations in the industry with a nationwide presence."
    },
    {
        id: 3,
        img: [Person3],
        name:"Prakash G",
        role:"PROJECT MANAGER",
        email:"prakash.edex@gmail.com",
        mobile:"+91 9177455636",
        bio:"An engineer holding around 10 years of experience at various industries associated with EDEX Team since 2014 and worked really hard to acquire detailed insight into the University Requirements, application formalities and intricacies of visa formalities. He has been at the forefront to ensure that the students are able to navigate through the cumbersome process with ease."
    },
    {
        id: 4,
        img: [Person4],
        name:"Solomon Pramod",
        role:"IELTS | GRE/GMAT TRAINER",
        email:"Solomon.edex@gmail.com",
        bio:"Since 2014, he is a graduate and with my business background, responsible for developing the team and ensuring the best result of students individually. He prepares the students to gain good scores &amp; offers easy-to-follow guidance and exam tips designed to guarantee exam success. He is responsible for planning and delivering a high quality test preparation classes which are multinational and might also be mixed ability. He will conduct mock test on Friday afternoons ensuring the students receive detailed feedback."
    },
    {
        id: 5,
        img: [Person5],
        name:"Jagan Raju",
        role:"REGIONAL MANAGER",
        mobile:"",
        bio:"Born in the IIT Madras campus, grew up in an environment of only education all around. 20 years of experience in International BPO industry, with an in depth knowledge of International Immigration Laws. Corporate Trainer, Personality development & Career development Trainer. Community service: Coaching Under privileged school children to choose to go abroad. Strongly Believe Indian youth should study abroad and explore the world. A radical change is necessary for the education sector of our country. International Education for all Indians to create scholars.Proud to be part of Edex Academy to create International opportunities for Indian students."
    }
];

function About() {
  return (
    <div className='about-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                             
                <h1 className='text-center fw-semibold'>About Us</h1>
            
                <p className='text-center w-75 mb-5'>EDEX Academy USA Ltd started 2024 provides International Education services to the students desiring to further their educational careers in overseas educational institutions. EDEX has its headquarters in Auckland, New Zealand, and Registered Offices in Malaysia, Sri Lanka, Nepal, UK, USA, Canada, which helps in facilitating the process.</p>

            </div>
        </header>

        <div className='container my-5'>
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5'>More About Us</h2>
                    <p>HOLISTIC APPROACH: In our guidance sessions, we advise each student personally right from career guidance to Job opportunities after completion of the course.</p>
                    <p>OPEN LIST OF UNIVERSITIES: We shortlist the universities based on each individual’s requirement and eligibility in terms of Program/Country/Intake/Budget/Job Opportunities etc.</p>
                    <p>TRANSPARENT SERVICES: We have taken special care and tailored our services so that they are completely transparent and proceed in constant conjunction with student’s participation.</p>
                    <p>EDEX TEAM: Advisors are trained professionals and expertise in all aspects of application processing i.e Short listing of Universities/ Application Follow-up / Admission and application requirement / Advise on Education Loan / Medicals and Police Clearance arrangements / Visa Documentation assistance / File submission / MOCK Interview Preparation etc.,</p>
                    <p>ADMISSIONS TO TOP RANKED UNIVERSITIES/ RARE PROGRAMS: We are among very few international educational facilitators who have the required expertise and have experience of applying to best universities. We also specialize in providing guidance to undergraduate students applying for best part of countries.</p>
                    <p>FEEDBACK FROM FORMER STUDENTS: More than 70% of our students come through past students referral. This is ample testimony to our services and satisfaction levels of our students</p>
                    <Link to="/contact">
                        <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={AboutUsSectionImg} style={{width:370,height:350}} alt="" />
                </div>
            </div>
        </div>

       
                   
        <div className='container my-5'>
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5 ' style={{color:'green'}}><b>Edex Profile</b></h2>
                    <p>Prince endeavors to fulfill the slogan “Start with a dream…Finish with a future.”</p>
                    <p>EDEX Academy NZ Ltd started 2007 provides International Education services to the students desiring to further their educational careers in overseas educational institutions. EDEX has its headquarters in Auckland, New Zealand, and Registered Offices in Malaysia, Sri Lanka, Nepal, UK, USA, Canada, which helps in facilitating the process.</p>
                    <p>EDEX have also developed a unique structure which enables it to access a large pool of experts and consultants who are always willing to take on challenges and assist our international students to find simple solutions to complex challenges.</p>
                    <p>The facilitation process is greatly enhanced by Prince Kumar, Managing Director, along with Veronica Samuel, CEO, Prabhu Rajan, Regional director with a dedicated team of experts in Edex group.</p>
                    <p>His strengths in international business prompted Prince to assist 100’s of students to fulfill their dreams of overseas education and successfully signed MoU’s with leading institutions overseas. He and his team members are certified agents under ENZ</p>
                    <p>Prince is one of the board members of the India New Zealand Business Council INZBC.</p>
                  
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={EdexTeamPicture} className='img-fluid h-50  w-90' alt="" />
                </div>
               
               
            </div>


            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <p className='mb-4 mb-lg-5 ' style={{backgroundColor:'#B5DFB2',height:90,width:350,textAlign:'center',borderRadius:20}}><b>{veronica.name}</b> <br/> e-MAIL:{veronica.email} <br/> mobile : {veronica.mobile}</p>
                    <p>{veronica.bio}</p>                    
                 
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={Person1} className='img-fluid h-70  w-100' style={{borderRadius:20}} alt="" />
                </div>
                    
            </div>

        </div>




        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <h2 className='text-center mb-5'>Our Team</h2>
                <div className='row g-4'>
                    {persons.map((person) => (
                        <div key={person.id} className='col-md-4'>
                            <img src={person.img} className='img-fluid' alt="" />
                            <div style={{textAlign:'center'}}>
                                <h2><i><b>{person.name}</b></i></h2>
                                <h5><i>{person.role}</i></h5>
                                 <h6>email : {person.email}</h6> 
                                 <h6>mobile : {person.mobile}</h6>
                                <p>{person.bio}</p>
                            </div>
                        </div>
                     
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default About;