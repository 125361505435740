import React from 'react';
import './Newzealand.css'; 
import NewzealandImage from "../../../Images/newzeland.jpg";
import FaqAustralia from '../Singapore/FaqAccordion/FaqAccordion';
import FaqNewzealand from './FaqAccordion/FaqAccordion';

const Nz = () => {
  return (

    <div className='newzealand-page'>
    <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
            <h1 className='text-center fw-semibold'>New Zealand</h1>
        </div>
    </header>



    <div className="container my-5">
      <div className="card mb-4">
        <div className="row no-gutters">
          <div className="col-md-8">
            <img src={NewzealandImage} className="card-img" alt="Education" />
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">New Zealand </h5>
              
              <p className="card-text">
              <span className='first-letter'>  N </span>  ew Zealand offers a world-class education in a postcard-perfect environment. New Zealand has an excellent, centrally managed quality assurance system. The New Zealand Qualifications Authority (NZQA) requires that institutions are registered to enrol international students and ensures compliance with qualifications standards. It’s highly modern, safe, and prosperous, with boundless opportunity for people of all nationalities. All 8 of New Zealand’s universities rank in the Top 100 QS World University Rankings for 2017, with 39 subjects recognised in this list.</p>
             
             <p>
             <strong> UNIVERSITIES: </strong>
             There are eight public universities in New Zealand, which offer degrees are teaching and research-based.</p>

             <p>
             <strong>INSTITUTES OF TECHNOLOGY AND POLYTECHNICS</strong>
             Courses are usually vocational and skills-based, ranging from certificate level through to bachelor degree and postgraduate level. </p> 
                
              <p>
                <strong>PRIVATE TERTIARY AND TRAINING INSTITUTIONS  </strong>
                Offer professional certificates, diplomas and degrees in a diverse range of subjects. More than 800 such establishments are registered with the New Zealand Qualifications Authority.
              </p>
             
            </div>
          </div>
        </div>
      </div>



    
    

               

      
           
        <div className="py-5">
            <FaqNewzealand></FaqNewzealand>
        </div>

    </div>
   </div> 
  );
};

export default Nz;
